.scanner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .scanner_container {
    width: 90%;
  }
}
.messages {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  padding-top: 8px;
  width: 90%;
}
.box {
  padding: 4px 0px 6px 0px;
  border-radius: 5px;
  flex-basis: 100%;
  width: 100%;
  text-align: center;
}
.success {
  color: #fff;
  background-color: #07bc0c;
  text-transform: uppercase;
}
.error {
  color: #fff;
  background-color: #dc3545;
  text-transform: uppercase;
}
.passwd {
  position: relative;

  .show_password {
    display: inline-block;
    padding: 0 4px 4px 4px;
    cursor: pointer;
    position: absolute;
    right: 6px;
    bottom: 2px;

    svg {
      width: 24px;
      height: 24px;

      path {
        fill: #5c636a;
      }
    }
  }

}
.subscriber {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;

  svg {
    width: 18px;
    height: 18px;
    margin-right: 8px;
    margin-top: 4px;
  }
  .info {
    font-size: 0.8em;
  }
}
.icons {
  display: flex;
  svg {
    margin: 0;
    width: 18px;
    height: 18px;
  }
  span.came {
    cursor: pointer;
    padding: 0px 6px 4px 6px;
    svg {
      fill: #07bc0c;
    }
  }
  span.delete {
    padding: 0px 6px 4px 6px;
    cursor: pointer;
    svg {
      fill: #dc3545;
    }
  }
  .registred {
    svg {
      fill: #dc3545 !important;
    }
  }
}
.info {
  position: relative;
  //width: calc(100vw - 152px) !important;
  padding-right: 4px;
  span {
    text-wrap: normal;
    overflow-wrap: break-word;
  }
}
.date {
  margin-left: 26px;
  font-size: 0.8em;
}
.type {
  position: absolute;
  top: 0;
  left: 4px;
  svg {
    width: 18px;
    height: 18px;
    margin-top: -2px;
    margin-right: 8px;
  }
}
.name {
  display: inline-block;
  margin-left: 26px;
}
.link {
  margin-left: 26px;
  font-size: 0.9em;
  a {
    overflow-wrap: break-word;
  }
}
.control {
  padding: 0 !important;
  text-align: center;
  width: 1%;
  vertical-align: top;
  svg {
    padding: 8px;
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
}
.approved * {
  color: var(--moder_approved) !important;
}
.declined * {
  color: var(--moder_declined) !important;
}
.approve {
  fill: #1cbb8c;
}
.decline {
  fill: #dc3545;
}

.legend svg {
  width: 18px;
  height: 18px;
  margin-right: 8px;
}
.types {
  span {
    display: inline-block;
    width: 16px;
    height: 12px;
    margin-right: 6px;
  }
  .moderation span {
    background: rgb(73, 80, 87);
  }
  .approved span {
    background: var(--moder_approved);
  }
  .declined span {
    background: var(--moder_declined);
  }
}

@media (max-width: 990px) {
  .info {
    width: calc(100vw - 152px) !important;
  }
  .link {
    a {
      width: calc(100vw - 180px);
    }
  }
}
.chapaev {
  max-width: 400px;

  img {
    border-radius: 5px;
    width: 100%;
  }

  button {
    width: 100%;
  }

}
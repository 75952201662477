.stat {
  svg {
    width: 18px;
    height: 18px;
    margin-top: -2px;
    margin-right: 16px;
  }
}

.reports {
  svg {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
}

:root {
  --moder_approved: #1cbb8c;
  --moder_declined: #dc3545;
}

.table {
  td {
    //padding: 0px;
  }
}
.approved {
  color: var(--moder_approved);
}
.declined {
  color: var(--moder_declined);
}

.control {
  padding: 0 !important;
  text-align: center;
  width: 1%;
  vertical-align: top;
  svg {
    padding: 8px;
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
}


.name {
  padding-left: 12px !important;
  position: relative;
  cursor: pointer;

  .type {
    display: inline-block;
    padding-right: 8px;
    height: 100%;
    svg {
      width: 18px;
      height: 18px;
    }
  }

  .chevron {
    position: absolute;
    fill: #8f95a1;
    right: 8px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    transition: all 0.5s ease;
  }
  .details {
    height: 0;
    font-size: 0.8em;
    padding-left: 16px;
    padding-top: 4px;
    overflow-y: hidden;
    transition: all 0.5s ease;
  }
}
.name.opened {
  .chevron {
    transform: rotate(180deg);
  }
  .details {
    height: 160px;
  }
}

.approve {
  fill: #1cbb8c;
}
.decline {
  fill: #dc3545;
}

.legend svg {
  width: 18px;
  height: 18px;
  margin-right: 8px;
}
.types {
  span {
    display: inline-block;
    width: 16px;
    height: 12px;
    margin-right: 6px;
  }
  .moderation span {
    background: rgb(73, 80, 87);
  }
  .approved span {
    background: var(--moder_approved);
  }
  .declined span {
    background: var(--moder_declined);
  }
}
.page404 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #7c7c7c;
  font-family: "Inter-Regular", Helvetica;
  height: 100%;

  .image {
    width: 35%;
  }

  .descr {
    text-align: center;
  }

}

@media (max-width: 990px) {
  .page404 .image {
    width: 80%;
  }
}
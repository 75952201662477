:root {
  --button-size: 42px;
}
.type {
  width: auto;
}
.add_button {
  width: var(--button-size);
  height: var(--button-size);
  border-radius: 50%;
  background: #8e042b;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;

  svg {
    width: 80%;
    height: 80%;
  }
  svg * {
    fill: #fff !important;
  }

}
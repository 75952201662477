.edit {
  position: relative;
  svg {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
}
.rem {
  height: 100px !important;
}
.row {
  border: 1px solid #dee2e6;
  border-radius: 5px;
  padding: 3px 12px 10px 12px;
  background: #fff;
  margin-bottom: 4px;

  label {
    font-size: 0.85em;
    color: #a0a2a4;
  }
  div {
    width: calc(100%);
    word-wrap: break-word;
    line-height: 1.2em;
    margin-top: -3px;
  }
}
.subscribers {
  background: #dc3545;
  color: #fff;
  font-size: 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: absolute;
  bottom: 8px;
  right: 0;
  opacity: 0.9;
  pointer-events: none;
}